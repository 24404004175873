body {
  margin: 0;
  padding: 0;
  /* font-family: 'Times Numeral Roman', 'Raleway', open-sans; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'lnum' 1; */
  overflow-x: hidden;
  /* overflow-y: auto; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}
