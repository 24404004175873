@import url(https://fonts.googleapis.com/css?family=Nunito&text=0123456789);

/* nunito-regular - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/nunito-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Nunito Regular'), local('Nunito-Regular'),
         url('./fonts/nunito-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/nunito-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v12-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
  }
$font-family: 'Nunito', 'Raleway', sans-serif;
// $font-variant-numeric: lining-nums!important;

