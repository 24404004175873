$iron: #D1D3D4;
$athens-gray: #eff1f3;
$curious-blue: #3095DE;
$mariner: #2b82c1;

.table__wrapper {
    position: relative;
}

.table__scroller {
    margin-left: 141px;
    overflow-x: auto;
    overflow-y: visible;
    padding-bottom: 5px;
    width: calc(100% -100px);
}

.table__stickycol {
    left: 0;
    position: absolute;
    top: auto;
    width: 270px!important;
}

.new-row {
    display: flex;
    flex-direction: row;
    margin-top: 5px;

    input {
        cursor: pointer;
    }

    &__btn {
        border-radius: 0px;
        margin-top: 1px;
        background-color: #3095DE;
        color: #fff;
        height: 40px;

        &:disabled{
            color: #ffffff!important;
            cursor: not-allowed!important;
        }

    }

    button {
        &:disabled{
            color: #ffffff;
            cursor: not-allowed!important;
        }
    }
}

.text--no-border, .text__large--no-border {
    border: none !important;
    border-radius: 1px !important;
    padding: 4px !important;
    line-height: 1!important;
}

.table_menu__link {
    width: 100px;

    i {
        margin-right: 15px;
    }
}

.table {
    overflow-x: auto;
    overflow-y: hidden;
    display: grid;

    &__group {
        &__menu {
            margin-top: 10px;

            &__icon {
                font-size: 20px;
                
                &--main {
                    font-size: 20px;
                    margin-bottom: 12px;
                }
            }

            &__row {

                i {
                    margin-right: 10px;
                }

                margin-top: 5px;
                margin-bottom: 5px;
                margin-right: 10px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

            }
        }
    }

    &::-webkit-scrollbar-track
    {
        margin-left: 25px;
        background-color: #ffffff;  
    }

    &::-webkit-scrollbar
    {
        height: 5px;
        background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
        background-color: #767676;
    }

    thead {
        display: grid;
        width: 100%;
    }

    tbody {
        display: flex;
        flex-direction: column;
        width: 100%;
    
    }

    td {
        padding: 0px!important;
        padding-bottom: 0px;
    }

    padding: 10px;
    padding-left: 7px;
    padding-bottom: 0px;
    width: 100%;
    outline: none;
    overflow-x: auto;
    display: block;

    .ant-form-item {
        margin-bottom: 0px!important;

    }

    form {
        padding: 0px!important;
        padding-left: 5px!important;
        padding-right: 5px!important;
    }

    &__rc {
        outline: none;
        margin-top: 0px;
        display: flex;
        margin-right: 3px;

    }

    &__column--first {
        flex: 1!important;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 370px;

    }

    &__column {
        width: 100%;
        outline: none;
        padding: 0px!important;
        flex: 0;

        form { 
            width: 100%;

            input { 
                background-color: $athens-gray;
                color: $mariner;
            }
        }

        .draghandle {
                
            width: 7px;
            min-width: 7px;
            height: 40px;
            background-color: $mariner;
            border-bottom: solid;
            border-bottom-color: #fff;
            border-bottom-width: 1px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            -webkit-transition: width 0.3s; /* For Safari 3.1 to 6.0 */
            transition: width 0.3s;

            &--active {
                width: 50px;
            }

            &:hover {
                background-color: lighten($mariner, 30%);
            }

            &:focus {
                background-color: lighten($mariner, 30%);
            }

            &:disabled{
                cursor: not-allowed!important;
            }

            i {
                color: #ffff;
                font-size:17px;
            }
        }

        // width: 100%;
        // height: 30px;
        // display: flex;
        // align-content: center;
        // justify-content: center;

        // nz-dropdown {
        //     // display: none!important;

        //     &__icon {
        //         font-size: 21px!important;
        //         cursor: pointer;
        //     }

        //     &:focus {
        //         display: inline-block!important;
        //     }

        //     &__item {
        //         &__icon {
        //             font-size: 15px;
        //             padding-right: 10px;
        //         }
        //     }
        // }

        &:hover { 

            nz-dropdown {
                display: inline-block!important;
            }
        }

        
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;

        // padding-left: 19px;

        &__columns--first {
            flex: 1!important;
            min-width: 349px!important;
        }

        &__input {
            width: 100%;
            min-width: 163px;

            &__group-title {
                font-size: 24px;

                &:disabled{
                    background-color: #ffffff;
                    cursor: not-allowed!important;
                }
            }

            &:disabled{
                cursor: not-allowed!important;
            }

        }

        &__columns {

            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: flex-start;
            width: 100%;
            flex: 0;
            min-width: 174px;


            nz-dropdown {
                display: none!important;
    
                &__icon {
                    font-size: 21px!important;
                    cursor: pointer;
                }
    
                &:focus {
                    display: inline-block!important;
                }
    
                &__item {
                    &__icon {
                        font-size: 15px;
                        padding-right: 10px;
                    }
                }
            }
    
            &:hover { 
    
                nz-dropdown {
                    display: inline-block!important;
                }
            }

            &__container {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                min-height: 52px;
                flex-grow: 0;
                padding-top: 10px;
                width: 100%;

                i {
                    color: #c8e5f3;
                    cursor: pointer;
                }

                i:hover {
                    color: #2b82c1;
                }

                input {
                    width: 100%;
                }


                &:hover { 

                    nz-dropdown {
                        display: inline-block!important;
                    }
                }
            }

            &__container--first {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                flex-grow: 1;
                min-height: 52px;
                margin-left: 10px;
                min-width: 250px!important;
                
                input {
                    width: 100%;

                    &:disabled{
                        background-color: #ffffff;
                        cursor: not-allowed!important;
                    }
                }
            }

            form { 
                width: 100%;
            }
        }

        &__menu {
            width: 150px;
        }

        &__menu__container {

    
    
            display: flex!important;
            flex-direction: column;
            align-content: center;
            justify-content: flex-end;
            align-items: center;
            font-size: 22px;
            width: 45px;
            padding-bottom: 6px;
            padding-right: 5px;
            margin-bottom: 2px;
        
            &__dropdown {
                cursor: pointer;
                
                &__icon {
                    font-size: 23px!important;
                    cursor: pointer;
                }
    
                &__item {
                    width: 100px!important;
                    span {
                        min-width: 80px!important;
                        padding: 10px;
                    }
                }
            }

        }
    }

    .row {


        &__content {
            outline: none;
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 40px;
            justify-content: center;
            align-items: center;
            text-align: left;
            width: 100%;

            &__menu {
                padding-right: 5px;
                min-width: 23px;

                &__delete {
                    color: #EB144C;
                }
            }

            &__column {
                flex-grow: 0;

            }

            &__column--first {
                flex-grow: 1;
                min-width: 250px;
                position: sticky;
            }


        }

        &__terminator {
            width: 30px;
            min-width: 35px;
            max-width: 35px!important;
            height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-bottom: solid;
            border-bottom-width: 1px;
            border-bottom-color: $iron;
            padding: 0px!important;

            &__body {
                background-color: $athens-gray;
                width: 100%;
                height: 100%;
            } 

            &__border {
                background-color: darken($athens-gray, 3.5%);
                width: 10px;
                height: 100%;
            }
        }

    }

    &__row {
        // margin-bottom: 2px;
        width: 100%;
        outline: none;
        padding: 0px!important;
        // padding-right: 12px!important;
        // padding-left: 1px!important;

        &__menu {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            padding-right: 5px;
            height: 100%;
            width: 20px;
            min-width: 20px;
            max-width: 20px;
            padding-left: 5px;
            
            &__dropdown {
                display: none!important;
                
            }

            &:hover {
                width: 20px!important;
                min-width: 20px!important;
                max-width: 20px!important;
    
                nz-dropdown {
                    cursor: pointer;
                    display: inline-block!important;
        
                    &:focus {
                        display: inline-block!important;
                    }

                    &:active {
                        display: inline-block!important;
                    }
                }
            }

            &__icon {
                font-size: 15px;
            }
        }

        &__container {
            width: 100%;
        }


        &__new {
            align-items: center;
            text-align: left;
            width: 100%;
            padding-left: 21px;
            padding-right: 14px;

            input {
                margin-right: 1px!important;
                margin-left: 9px;
                padding-left: 17px!important;
                margin-top: 1px!important;
                height: 40px!important;
                border: none!important;
                border: solid!important;
                border-width: 1px!important;
                border-color: #E2E6E9!important;
                border-radius: 1px!important;

                &:focus {
                    outline-width: 0px!important;
                    box-shadow: none!important;
                    border-color: $curious-blue!important;
                }        

                &:disabled{
                    background-color: #ffffff;
                    cursor: not-allowed!important;
                }
            }
        }

        dyn-cell {
            width: 100%;
        }
        
        &__cell__container {
            cursor: pointer;
            border: none;
            border-right: solid;
            border-right-width: 1px;
            border-right-color: #cdd3da;
            background-color: $athens-gray;
            border-bottom: solid;
            border-bottom-width: 1px;
            border-bottom-color: $iron;
            padding: 5px;
            width: 100%;
            height: 40px;

            .select-cell {
                width: 100%;
                display: block;
                min-width: 164px;
                height: 100%;            
            }

        }

        &__cell__container--nopadding {
            cursor: pointer;
            border: none;
            border-right: solid;
            border-right-width: 1px;
            border-right-color: #cdd3da;
            background-color: $athens-gray;
            border-bottom: solid;
            border-bottom-width: 1px;
            border-bottom-color: $iron;
            width: 100%;
            height: 40px;

            .select-cell {
                width: 100%;
                display: block;
                min-width: 174px;
                height: 100%;            
            }

        }

        &__cell__container__first {
            width: 100%;
            min-width: 250px;
        }

        &__cell--text {
            
            &:focus {
                outline-width: 0px;
                border: dashed;
                border-width: 1px;
            }
            
            &:hover {
                border: dashed;
                border-width: 1px;
                cursor: pointer;
            }

            width: 100%;
            align-items: center;
            border: none;
            border-radius: 1px;
            background-color: $athens-gray;
            color: #2b82c1;
            display: flex;
            height: 27px;
            padding: 5px;
            text-align: left;
        }

        outline: none;
        align-items: center;
        // display: flex;
        height: 40px;
        justify-content: center;
        align-items: flex-end;
        text-align: left;
        width: 100%;
    }
}

dyn-row {
    outline: none;
}

dyn-table {
    outline: none;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 0px) and (max-device-width : 680px) {
    .table__row__cell__container--nopadding .select-cell {
        // min-width: 130px;
    }

    .table {
        form { 
            overflow: hidden;
        }
    }
    //     &__column {
    //         min-width: 130px;

    //         &--first {
    //             min-width: 200px;
    //             z-index: 0;
    //         }
    //     }

    //     &__header {
    //         &__menu {
    //             &__container {
    //                 flex: none;
    //                 // padding-right: 20px;
    //             }
    //         }

    //         &__columns {
    //             flex: none;
    //             min-width: 170px;

    //             &--first {
    //                 min-width: 100px!important;
    //                 flex: none;
    //             }
    //         }
    //     }
    // }
}