
.submenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    z-index: 0;

    button {
        position: absolute;
        left: 210px;
    }

    .ant-menu-submenu {
        .ant-menu-submenu-title {
            padding-left: 16px!important;
        }
    }

    .ant-tree {
        width: 100%;
        z-index: 0;
        .ant-tree-switcher {
            position: absolute!important;
            right: 60px!important;
            top: 15px!important;

        }

        .ant-tree-node-selected {
            color: #039BE5!important;
            &::before {
                background: #ffffff!important;
            }

            .ant-tree-title {
                color: #039BE5!important;
            }

            .ant-tree-iconEle {
                // color: rgba(0, 0, 0, 0.65);
                color: #039BE5!important;
            }
        }

        .ant-tree-treenode-selected {
            .ant-tree-switcher {
                // color: rgba(0, 0, 0, 0.65)!important;
                color: #039BE5!important;

            }

        }

        :first-child {
            .ant-tree-node-content-wrapper {
                height: 37px!important;
                display: flex;
                flex-direction: row;
                align-items: center;
    
                &::before {
                    height: 37px!important;
                }
    
                .ant-tree-iconEle {
                    // padding-left: 12px;
                    // margin-right: 5px;
                }
    
                .ant-tree-title {
                    // padding-left: 12px!important;
                }
            }

            .ant-tree-child-tree {
                .ant-tree-node-selected {
                    &::before {
                        background: #ffffff!important;
                    }

                    .ant-tree-title {
                        color: #039BE5!important;
                    }

                }

            }

        }
    }

    li {
        flex-grow: 1;
        width: 100%;

        #\32 \$Menu {
            // margin-left: 15px!important;

            li {
                height: 50px;
            }
        }
    
        .ant-btn {
            margin-top: 7px!important;
        }

        .ant-menu-item-selected {
            // width: calc(100% + 40px)!important;
        }

        .ant-menu-submenu-title {
            max-width: 240px!important;

            .ant-menu-submenu-arrow {
                position: absolute;
                left: 193px;
            }

        }

        ul {
            
            li {
                // padding-left: 48px!important;


                a {
                    width: 195px;
                    max-width: 195px!important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }

    }

}

.draggable-source--is-dragging {
    ul {
        transform: scale(0.8);
        background: lightgray;
    }

    .table__row {
        transform: scale(0.8);
        background: lightgray;

        &__cell__container {
            background-color: #e6f7ff;
        }

        &__cell--text {
            background-color: #e6f7ff;
        }

        &__menu {
            background-color: #fff;
        }

        .row__terminator__body {
            background-color: #e6f7ff;
        }
    }
}

.draggable-mirror {
    ul {
        background: #ababab82;
        width: 150px;
        transform: rotate(-5deg);
        display: block;
    }
}

dyn-sub-menu {
    outline: none;
}


.menu {

    overflow-y: visible;
    overflow-x: hidden; 
    height: 100%;
    border-right: none;
    
    &::-webkit-scrollbar-track
    {
        background-color: #ffffff;  
    }

    &::-webkit-scrollbar
    {
	    width: 6px;
	    background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb
    {
	    border-radius: 10px;
	    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
	    background-color: #767676;
    }

    &__item {
        outline: none;

        &--dashed {
            outline: dashed;
        }

        &__button {
            float: left; 
            left:200px; 
            top: 11px; 
            margin-bottom:-24px;
            z-index: 1000;

        }

        &__subitems {
            outline: none;
        }

        &__badge {
            margin-left: auto!important;
            margin-right: 13px!important;

            .ant-badge-count {
                background: #3095DE;
                height: 18px!important;
                min-width:18px!important;
                line-height: 18px!important;
            }
        }

        &__title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            align-content: center;
        }
    }

    &__folder-item {
        position: relative;
        &__input {
            width: 93%!important;
        }
        &__edit {
            position: absolute;
            top: 14px;
            right: 30px;
        }
        &__delete {
            position: absolute;
            top: 14px;
            right: 10px;
        }
    }

    &__submenu-item {

        span {
            width: 10px!important;
        }

        position: relative!important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        outline: none;

        &:hover {
            li {
                // padding-left: 32px!important;
                outline: none!important;
                // transition: padding-left .3s linear;
                // -moz-transition: padding-left .3s linear;
                // -o-transition: padding-left  .3s linear;
                // -webkit-transition: padding-left  .3s linear;
            }

            li:hover {
                // padding-left: 32px!important;
            }
        }

        li {
            padding-left: 42px!important;
            outline: none!important;
            // transition: padding-left .3s linear;
            // -moz-transition: padding-left .3s linear;
            // -o-transition: padding-left  .3s linear;
            // -webkit-transition: padding-left  .3s linear;
        }

        li:hover {
            // padding-left: 32px!important;
        }

        &__button {
            float: right;
            right: 50px;
            top: 7px;
            z-index: 1000;
            position: absolute!important;
            
            span {
                display: contents!important;
            }
        }

        .draghandle {
            cursor: pointer;
            transform: rotate(-45deg);
            max-height: 40px;
       }
    }
}

.menu-item-mirror {
    transform: rotate(-5deg);
    display: block;
    
    ul {
        background: #ababab82;
        width: 200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        transform: rotate(-5deg);
    }

    .table__row {
        transform: rotate(-2deg);
    }
}

.draghandle {
    cursor: pointer;
}

.ant-menu-item, .ant-menu-submenu {
    text-align: start;
    font-family: $font-family;
    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'lnum' 1;
  
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    width: 100%!important;
}

.ant-breadcrumb-separator {
    margin: 0 12px;
}

.folder-subfolder {
    .ant-menu-submenu-title {
        padding-left: 12px!important;
    }
}

.foldermenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 240px;

    span {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 158px;
        max-width: 158px!important;
        white-space: nowrap;
    }

    &:hover{
        padding-left: 0px!important;
    }

    .edit {
        margin-right: 10px;

        &:hover {
            color: #1890ff;
            cursor: pointer;
        }
    }
}

.firstitem {

    :first-child {
        .ant-tree-iconEle {
            padding-left: 12px;
            margin-right: 5px;
        }

        .ant-tree-title {
            padding-left: 12px!important;
        }
    }
}

.subitemnoicon {

    .ant-tree-iconEle {
        padding-left: 0px!important;
        margin-right: 0px!important;
        width: 11px!important;
    }

    .ant-tree-title {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 210px;
        padding-left: 0px!important;
    }
}

