.btn-add-board {
    align-self: flex-start;
    margin-top: 9px;
}

.text, .text__large {
    &--no-border {
        border: none!important;
        border-radius: 1px!important;
        padding: 4px!important;
        line-height: 1!important;

        &:hover {
            outline-width: 0px;
            border: dashed!important;
            border-width: 1px!important;
            cursor: pointer!important;
            padding: 3px!important;
        }

        &:focus {
            padding: 3px!important;
            outline-width: 0px!important;
            border: dashed!important;
            border-width: 1px!important;
        }
    }

}

.text__large--no-border {
    font-size: 30px!important;
}

.choose-board {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(180px, 3fr) 9fr;
    grid-template-areas: "menu content";
    overflow: hidden;
    height: calc(100vh - 54px);
    
    dyn-menu {
        height: calc(100vh - 54px);
        grid-area: menu;
    }

    &__content {
        padding-left: 15px;  
        grid-area: content;

        .board_img {
            width: 100%;
        }
    }    

}

.dyn-modal-content {
    .ant-modal {
        padding: 0px;
        overflow: hidden;
    }

    .ant-modal-body {
        padding: 0px!important;
    }

    .ant-modal-content {
        height: 100%!important;
        border-radius: 0px;
    }
}

@keyframes move
    {
        0%   {
          left: 0%;
          transform: rotateZ(0deg) ;
        }
        15% {
          transform: rotateZ(360deg);
        }
        30% {
          transform: rotateZ(720deg);
        }
        45% {
           transform: rotateZ(1080deg);
        }
        60% {
            transform: rotateZ(1440deg);
        }
        75% {
            transform: rotateZ(1800deg);
        }
        90% {
            transform: rotateZ(2160deg);
        }
        100% {
          left: 100%;
          transform: rotateZ(2520deg);
        }
    }

.board {

    &__empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 100px;

        .explain {
            margin: 20px;
            font-size: 20px;
        }

        img { 
            height: 70%;
        }
    }

    &__load-indicator {
        width: 18px;
        height: 18px;
        // color: red;
        // background-color: red;
        position: absolute;
        top: 55px;
        animation: move 22s linear both infinite;
        -webkit-animation: move 22s linear forwards infinite;
        -moz-animation: move 22s linear forwards infinite;
        -o-animation: move 22s linear forwards infinite;
    }

    &__header {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 20fr 1fr;
        grid-template-areas: "content menu";
        padding-right: 10px;

        &__btn {
            margin-left: 24px;
            margin-top: 18px;
        }
    }

    &__detail {
        margin-top: 40px;
        grid-area: "content";
        padding-right: 8px;
    }

    &__menu {
        display: flex!important;
        align-items: center;
        justify-content: flex-end;
        grid-area: "menu"!important;
        padding-right: 10px;

        button {
            i {
                font-size: 16px!important;
            }
        }
    }

    &__form {
        padding: 10px;

        &__description {
            // margin-top: 5px;
            margin-left: 2px;
            color: #3095DE!important;

            &:disabled{
                background-color: #ffffff;
                cursor: not-allowed!important;
            }
        }

        .description {
            margin-top: 4px;
            margin-left: 3px;

            &:disabled{
                background-color: #ffffff;
                cursor: not-allowed!important;
            }
        }

        .ant-form-item {
            margin-bottom: 0px!important;
        }
    }
}