.pricing {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 700px;

    &__header {
        display: flex;
        flex-direction: row;
        width: 80%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__section {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: 500px;

        border: solid 1px #e0e0e0;
        background-color: #FAFAFA;

        height: 80%;
        width: 30%;
        padding: 30px;

        &--main {
            box-shadow: 0 1px 15px #ccc;
            z-index: 1;
        }

        &__title {
            font-size: 25px;
            color: #414141;
            margin-bottom: 15px;
        }

        &__description {
            margin-bottom: 55px;
        }

        &__blurb {
            margin-bottom: 5px;
        }

        &__cost {
            font-size: 30px;
            color: #3095de;
            margin-bottom: 10px;
            margin-top: 10px;
        }

        &__cost-basis {

        }

        &__button {
            border: solid 2px #3095de;
            color: #3095de;
            position: absolute;
            bottom:  50px;
            padding: 20px;
            width: 80%;
            text-align: center;
            font-size: 20px;

            :hover {
                cursor: pointer;
                background-color: #3095de!important;
                color: #ffffff;
            }
        }

        &__button--main {
            border: solid 2px #3095de;
            background-color: #3095de;
            color: #ffffff;
            position: absolute;
            bottom:  50px;
            padding: 20px;
            width: 80%;
            text-align: center;
            font-size: 20px;
        }
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 0px) and (max-device-width : 680px) {
    .pricing {
        display: flex;
        flex-direction: column;
        min-height: calc(100% - 20px);


        a {
            width: 100%;
        }

        &__header {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 87%;
            height: 80vh;
            overflow: scroll;

            box-shadow: -2px 1px 17px 1px rgba(0,0,0,0.25);
            -webkit-box-shadow: -2px 1px 17px 1px rgba(0,0,0,0.25);
            -moz-box-shadow: -2px 1px 17px 1px rgba(0,0,0,0.25);
        }

        &__section {
            width: 100%;
            padding: 30px;
            height: 100%;
            display: block;

            &__button {
                position: inherit;
                width: 100%;
                bottom: 0px;
            }
        }    

        &__section {
            width: 100%;
            padding: 30px;
            height: 100%;
            display: block;

            &__button--main {
                position: inherit;
                width: 100%;
                bottom: 0px;
            }
        }    
    }
}
    