
// Colours

$logo-color: #f6f9fa;
$primary-color: #3095de;
$primary-color-dark: darken($primary-color, 20%);
$strapbox-headline-color: #e3f2f4;
$strapbox-subheading-color: #e3f2f4;
$transparent-black: rgba(0, 0, 0, 0.45);

$green: #0ca959;
$light-green: lighten($green, 5%);

// Fonts
$font-family: $font-family!important;


// Logo
$logo-font-size: 30px;
$logo-font-size-large: 44px;

body {
    font-family: $font-family;
    // -webkit-font-feature-settings: "lnum";
    // -moz-font-feature-settings: "lnum";
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    // font-feature-settings: 'lnum' 1;
    // font-weight: 400;
    // font-variant-numeric: $font-variant-numeric;
    // font-variant-numeric: lining-nums;
    // -moz-font-feature-settings: "lnum" 1;
    // -moz-font-feature-settings: "lnum=1";
    // -ms-font-feature-settings: "lnum" 1;
    // -o-font-feature-settings: "lnum" 1;
    // -webkit-font-feature-settings: "lnum" 1;
    // font-feature-settings: "lnum"!important;
}