.home__choice {
    display: grid;
    grid-template-rows: minmax(1fr, 80px) 5fr 5fr 5fr 0.5fr;
    grid-template-columns: 1fr;
    grid-template-areas: "header" "section" "section2" "section3" "footer";
    height: 100%;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 740px;

    &::before {
        content: '';
        background-color: #BDEBE0;
    }

    &::after {
        min-height: 740px;
        height: 100vh;
        width: 100vw;
        content: "";
        // background-image: url(/assets/img/snippy2.jpg), none;
        
        background-color: #BDB9B8;

        background-image: linear-gradient(177deg, #bdb9b8 0%, #d8d7da 62%);
                        

        //opacity: 0.8;
        background-size:     cover;
        background-repeat:   no-repeat;
        background-position: center center;  
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;   

        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }

    .brand {
        position: absolute;
        left: 15px;
        top: 9px;
        grid-area: header;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 10px;  

        h1 {
            font-size: 34px;
            font-weight: 300;
            // color: #414141c2;
            color: #ffffff;
        }
    }

    .section {
        grid-area: section;

        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 3fr 3fr 1fr;
        grid-template-areas: "blank cont pic";
        
        .main {

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            align-items: center;
            
            &__form {

                .domain_form {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                }

                .domain__ctrl {
                    min-height: 60px!important;
                }

                .new_domain {
                
                    &__form {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-content: center;
                        align-items: center;
                        padding: 69px;
                        width: 100%;
                    }

                    &__form-input {
                        margin-bottom: 50px;
                    }

                    &__form-item {
                        width: 100%;
                    }
                }

                dyn-signup {
                    width:100%;
                    min-width: 280px;
                }

                dyn-new-domain {
                    width: 100%;
                }

                dyn-join-domain {
                    width: 100%;
                }

                .icon {
                    font-size: 80px;
                    margin-bottom: 70px
                }

                .text {
                    font-size: 25px;
                    color: rgba(0,0,0,.65);
                }

                // -webkit-box-shadow: 10px 10px 197px -20px rgba(0,0,0,0.93);
                // -moz-box-shadow: 10px 10px 197px -20px rgba(0,0,0,0.93);
                // box-shadow: 10px 10px 197px -20px rgba(0,0,0,0.93);

                grid-area: cont;
                background-color: #fff;
                height: 100%;
                width: 100%;
                // border-top-left-radius: 5px;
                // border-bottom-left-radius: 5px;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                align-items: center;

                .domain__btn {
                    height: 73px;
                    font-size: 35px;
                    width: 100%;

                    span {
                        padding-right: 30px;
                    }

                }



                .new {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-top: solid;
                    border-bottom: solid;
                    border-top-width: 2px;
                    border-bottom-width: 2px;

                    border-top-color: black;
                    border-bottom-color: black;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-content: center;
                    align-items: center;

                    .icon {
                        font-size: 31px;
                        padding-left: 40px!important;
                    }

                    .text {
                        font-size: 34px;
                        color: $primary-color;
                    }

                }

                .new:hover {

                    .icon {
                        color: aqua;
                    }
                }

                .or {
                    margin-top: 30px;
                    margin-bottom: 30px;
                    font-size: 20px;
                }

                .join {
                    font-size: 34px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-top: solid;
                    border-bottom: solid;
                    border-top-width: 2px;
                    border-bottom-width: 2px;

                    border-top-color: black;
                    border-bottom-color: black;

                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-content: center;
                    align-items: center;

                    .icon {
                        font-size: 31px;
                        padding-left: 40px!important;
                    }

                    .text {
                        font-size: 34px;
                    }
                }
            }

            &__pic {
                grid-area: pic;
                background-image: url(/assets/img/dog-sm.jpg), none;
                background-size:     cover;
                background-repeat:   no-repeat;
                background-position: center center;  
                height: 100%;
                width: 100%;
            }
        }

        .domain__container {
            display: flex;
            flex-direction: row;          
        }

        &__img {
            width: 55px;
            height: 42px;
            margin-left: 11px;
            display: inline-block;
            background-image: url(/assets/img/logo-blue-sm.png), none;
            background-size: 53px 42px;

        }
    
    }
}


.home {
    // min-height: calc(100vh - 80px);
    grid-area: section;

    .brand {
        position: absolute;
        left: 15px;
        top: 9px;
        grid-area: header;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 10px;  

        h1 {
            font-size: 34px;
            font-weight: 300;
            color: #fff;
        }
    }


    &__header {
        position: relative;
        min-height: 80px;
        max-height: 80px;
        // background-color: #ffffff4f;
        grid-area: header;
    }

    &__section2 {
        padding: 50px;
        background-color: #fff;
        grid-area: section2;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__h1 {
            font-size: 50px;
            margin: 15px;
            text-align: center;
        }

        &__h2 {
            font-size: 30px;
            margin: 5px;
            width: 50%;
            text-align: center;
        }
    }

    &__section3 {
        padding: 50px;
        background-color: #EFF1F3;
        grid-area: section3;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__container {
            padding: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;

            .home-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
            }            

            .anticon {
                font-size: 80px;
            }

            .h1 {
                font-size: 30px;
                margin: 15px;
                text-align: center;
            }
    
            .h2 {
                font-size: 20px;
                margin: 5px;
                text-align: center;
            }
        }

    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__button {
        background-color: #2E84C2;
        padding: 15px;
        color: #fff;
        font-size: 20px;
        margin-bottom: 24px;
        cursor: pointer;

        &:hover {
            background-color: lighten(#2E84C2, 10%);
        }
    }

    &__h1 {
        color: #414141;

        font-family: $font-family;
        font-size: 28px;
        font-weight: 600;

        margin-bottom: 15px;
    }

    &__h2 {
        color: #414141;

        font-family: $font-family;
        font-size: 18px;

        margin-bottom: 15px;
    }

    &__links {
        position: absolute;
        right: 37px;
        top: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 10;

        a {
            margin-left: 30px;
            margin-right: 30px;
            // color: #414141c2;
            color: #ffffff;

            font-family: $font-family;
            font-size: 18px;
            font-weight: 500;

            &:hover {
                color: #2D83C1;
            }
        }

        .login-link-btn {
            border: solid;
            border-width: 1.5px;
            border-color: #ffffff;
            border-radius: 4px;
            padding: 5px;

            &:hover {
                border-color: #2D83C1;
            }
        }
    }

    &__sample {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background-color: #ffffff;
        width: 80%;
        padding: 50px;
        padding-top: 25px;

        img {
            width: 85%;
            // box-shadow: 0 1px 15px #ccc;
            box-shadow: -2px 1px 17px 1px rgba(0,0,0,0.25);
            -webkit-box-shadow: -2px 1px 17px 1px rgba(0,0,0,0.25);
            -moz-box-shadow: -2px 1px 17px 1px rgba(0,0,0,0.25);
        
        }


    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 0px) and (max-device-width : 680px) {
    .home__links {
        position: absolute;
        right: 27px;
        top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 10;

        a {
            margin-left: 18px;
            margin-right: 9px;
            color: #414141c2;
            font-family: $font-family;
            font-size: 14px;
            font-weight: lighter;
        }
    }

    .home {
        justify-content: flex-start;
        position: relative;
        min-height: calc(100vh - 80px);


        &__choice {
            grid-template-columns: minmax(1fr, 100vw);

            .brand {
                position: absolute;
                left: 10px;
                top: 10px;
                grid-area: header;
                display: flex;
                flex-direction: row;
                padding: 0px;
                padding-top: 9px;
        
                h1 {
                    margin-bottom: 0px;
                    font-size: 23px;
                    font-weight: 300;
                    // color: #414141c2;
                    color: #ffffff;
                }

                .section {
                    &__img {
                        width: 35px;
                        height: 28px;
                        margin-left: 0px;
                        display: inline-block;
                        background-image: url(/assets/img/logo-blue-sm.png), none;
                        background-size: 35px 28px;
                    }
                }
            }
        }
        
        &__section2 {
            &__h1 {
                font-size: 30px;
                margin: 15px;
                text-align: center;
                font-weight: 700;
            }
    
            &__h2 {
                font-size: 20px;
                margin: 5px;
                width: 90%;
                text-align: center;
            }
    
        }

        &__section3 {
    
            &__container {
                flex-direction: column;
                justify-content: center;

                .home-container {
                    margin-bottom: 40px;
                }
            }
        }
    
        &__sample {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // background-color: #ffffff;
            width: 87%;
            padding: 15px;
            padding-top: 25px;

            img {
                width: 105%;
                box-shadow: -2px 1px 17px 6px rgba(0,0,0,0.25);
-webkit-box-shadow: -2px 1px 17px 6px rgba(0,0,0,0.25);
-moz-box-shadow: -2px 1px 17px 6px rgba(0,0,0,0.25);
            }
        }

        &__h1 {
            text-align: center;
        }

        &__h2 {
            text-align: center;
        }
    }
}