.ant-input, .ant-menu, .ant-dropdown {
    font-family: $font-family;
    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'lnum' 1;
  
    // font-variant-numeric: $font-variant-numeric;
    font-feature-settings: "lnum"!important;
}

.ant-drawer-title {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: #3095de;
}

.ant-carousel {
    width: 317px;
    height: 91px;
    margin-bottom: 32px!important;

    .slick-dots {
        bottom: -19px!important;

        li {
            &.slick-active {
                button {
                    background-color: #595959!important;
                    height: 8px!important;
                }
            }
    
            button {
                background-color: darken(#EFF1F3, 40%)!important;
                height: 8px!important;
                border-radius: 10px;
            }
        }
    }
}


.editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  }
