@keyframes rotate
{
    0%   {
        transform: rotateY(0deg) ;
    }
    100% {
        transform: rotateY(360deg);
    }
}

@keyframes rotateFull
{
    0%   {
        transform: rotateY(0deg) ;
    }
    100% {
        transform: rotateY(360deg);
    }
}


.userprofile {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__deleteac-btn {
        width: 100%;
    }

    &__remoteac-btn {
        margin-top: auto;
    }

}

.ant-alert-info {
    margin-bottom: 10px;
}

.ant-alert-success {
    margin-bottom: 10px;
}


.post-auth {

    &__loader {
        .title {
            position: absolute;
            top: 7px;
            width: 100%;
            padding-left: 30px;
            padding-top: 24px;

            h1 {
                color: #fff;
            }
        }

        .ml2 {
            color: #fff;
        }

        background-color: #E8C3B9;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }


    &__content {
        overflow: hidden;
        display: grid;
        grid-template-rows: 65px 1fr;
        grid-template-columns: 255px 1fr;
        grid-template-areas: "header header"
                             "sidenav main";
        min-height: 100vh;
        margin: 0;
              
        dyn-side-menu {
            grid-row-start: 2;
            grid-row-end: 3;
            overflow: hidden;

        }    

        main {
            padding-left: 4px;
            grid-column-start: 2;
            grid-row-start: 2;
            overflow: hidden;
            overflow-y: auto;
            //height: 100%;
            // height: calc(100vh - 65px);

            @supports (-ms-accelerator:true) {
                /* IE Edge 12+ CSS styles go here */ 
                height: 100vh!important;
            }

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                /* IE10+ CSS styles go here */
                display: block!important;
                margin-top: 60px!important;
                height: calc(100vh - 60px)!important;
            }
        }


        
    }
}


.spinner {
  width: 60px;
  height: 60px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.new_domain__form {
    margin: 0px;
    width: 100%;
    padding: 0px!important;
    padding-top: 10px!important;

    .ant-row {
        width: 100%;
        padding-bottom: 20px;
    }
}

.sidenav-drawer {
    &__burger {
        display: none;
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 0px) and (max-device-width : 680px) {
    .post-auth {
        &__content {

            .toolbar {
                padding-left: 12px;

                .anticon {
                    font-size: 26px;
                    margin-right: 10px;
                }
            }

            grid-template-columns: 1fr;
            main {
                grid-column-start: 1;
            }
        }
    }

    .ant-drawer-body {
        .side-menu {
            display: block;
            border: none;
            margin: 0px;
        }
    }

    .sidenav-drawer {
        &__burger {
            display: block;
        }

        .ant-drawer-body {
            padding-top: 0px;
            padding-left: 10px;
        }
    }
}