.login-form-forgot {
    float: right;
  }

  .login-form-button {
    width: 100%;
  }

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    min-height: 378px;
    background-color: #ffffff;
    padding: 36px!important;
    width: 88%;
    
    &--forgot {
        float: right;
    }

    &__button {
        width: 100%;    
        margin-bottom: 20px;
    }

    &__heading {
        color: $primary-color;
        font-family: $font-family;
        font-size: 37px;
        margin-bottom: 20px;
    }

    &__textbox {
        width: 100%;
    }

    nz-form-item {
        width: 100%;
    }

    form {
        min-width: 100%;
    }
}

dyn-signup {
    width:89%;
}

.registration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    width: 100%;
    background-color: #ffffff;
    padding: 36px!important;

    nz-form-item {
        width: 100%;
    }

    .buttons {
        width:100%;

        button {
            margin-bottom: 10px;
        }
    }

    &__button {
        width: 100%;
        margin-bottom: 20px;
        background-color: $green!important;
        border-color: $green!important;
        margin-bottom: 7px!important;

        &:hover{
            background-color: $light-green!important;
            border-color: $light-green!important;
        }
    }


    &__heading {
        color: $primary-color;
        font-family: $font-family;
        -webkit-font-feature-settings: "lnum";
        -moz-font-feature-settings: "lnum";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'lnum' 1;
      
        font-size: 34px;
        margin-bottom: 40px;
    }
}
