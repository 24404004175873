.signup-agree {
    &--failed {
        span {
            color: red;
        }
    }
}

.domain__choice {
    display: grid;
    grid-template-rows: 1fr 5fr 0.5fr;
    grid-template-columns: 1fr;
    grid-template-areas: "header" "section" "section2" "footer";
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 740px;

    &::after {
        min-height: 740px;
        height: 100vh;
        width: 100vw;
        content: "";
        // background-image: url(/assets/img/snippy2.jpg), none;
        background-color: #BDB9B8;
        background-image: linear-gradient(177deg, #bdb9b8 0%, #d8d7da 62%);

        //opacity: 0.8;
        background-size:     cover;
        background-repeat:   no-repeat;
        background-position: center center;  
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;   

        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }

    .brand {
        position: absolute;
        left: 15px;
        top: 9px;
        grid-area: header;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 10px;  

        h1 {
            font-size: 34px;
            font-weight: 300;
            // color: #414141c2;
            color: #ffffff;
        }
    }

    .section {
        grid-area: section;

        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 3fr 3fr 1fr;
        grid-template-areas: "blank cont pic";
        
        .main {

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            align-items: center;
            
            &__form {

                .domain_form {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                }

                .domain__ctrl {
                    min-height: 60px!important;
                }

                .new_domain {
                
                    &__form {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-content: center;
                        align-items: center;
                        padding: 69px;
                        width: 100%;
                    }

                    &__form-input {
                        margin-bottom: 50px;
                    }

                    &__form-item {
                        width: 100%;
                    }
                }

                dyn-signup {
                    width:100%;
                    min-width: 280px;
                }

                dyn-new-domain {
                    width: 100%;
                }

                dyn-join-domain {
                    width: 100%;
                }

                .icon {
                    font-size: 80px;
                    margin-bottom: 70px
                }

                .text {
                    font-size: 25px;
                    color: rgba(0,0,0,.65);
                }

                // -webkit-box-shadow: 10px 10px 197px -20px rgba(0,0,0,0.93);
                // -moz-box-shadow: 10px 10px 197px -20px rgba(0,0,0,0.93);
                // box-shadow: 10px 10px 197px -20px rgba(0,0,0,0.93);

                grid-area: cont;
                background-color: #fff;
                height: 100%;
                width: 100%;
                // border-top-left-radius: 5px;
                // border-bottom-left-radius: 5px;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                align-items: center;

                .domain__btn {
                    height: 73px;
                    font-size: 35px;
                    width: 100%;

                    span {
                        padding-right: 30px;
                    }

                }



                .new {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-top: solid;
                    border-bottom: solid;
                    border-top-width: 2px;
                    border-bottom-width: 2px;

                    border-top-color: black;
                    border-bottom-color: black;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-content: center;
                    align-items: center;

                    .icon {
                        font-size: 31px;
                        padding-left: 40px!important;
                    }

                    .text {
                        font-size: 34px;
                        color: $primary-color;
                    }

                }

                .new:hover {

                    .icon {
                        color: aqua;
                    }
                }

                .or {
                    margin-top: 30px;
                    margin-bottom: 30px;
                    font-size: 20px;
                }

                .join {
                    font-size: 34px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-top: solid;
                    border-bottom: solid;
                    border-top-width: 2px;
                    border-bottom-width: 2px;

                    border-top-color: black;
                    border-bottom-color: black;

                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-content: center;
                    align-items: center;

                    .icon {
                        font-size: 31px;
                        padding-left: 40px!important;
                    }

                    .text {
                        font-size: 34px;
                    }
                }
            }

            &__pic {
                grid-area: pic;
                background-image: url(/assets/img/dog-sm.jpg), none;
                background-size:     cover;
                background-repeat:   no-repeat;
                background-position: center center;  
                height: 100%;
                width: 100%;
            }
        }

        .domain__container {
            display: flex;
            flex-direction: row;          
        }

        &__img {
            width: 55px;
            height: 42px;
            margin-left: 11px;
            display: inline-block;
            background-image: url(/assets/img/logo-blue-sm.png), none;
            background-size: 53px 42px;

        }
    
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 0px) and (max-device-width : 680px) {
    .domain__choice {
        .brand {
            position: inherit;
            left: 15px;
            top: 15px;
            grid-area: header;
            display: flex;
            flex-direction: row;
            padding: 0px;
            padding-top: 9px;

            h1 {
                margin-bottom: 0px;
                font-size: 23px;
                font-weight: 300;
                color: #414141c2;
            }
        }

        .section {
            grid-template-columns: 1fr 11fr 1fr;

            &__img {
                width: 38px;
                height: 31px;
                margin-left: 0px;
                background-image: url(/assets/img/logo-blue-sm.png), none;
                background-size: 37px 29px;
            }

            .main {
                &__form {
                    width: 100%;
                    overflow: scroll;
                }


                &__pic {
                    visibility: hidden;
                }
            }
        }
    }

    .login {
        &__heading {
            font-size: 31px;
        }
    }
}
    