.select {
    display: flex;
    flex-direction: row;

    &__field {
        display: flex;
        flex-direction: row;
        width: 100%;

        &--selected {
            outline: 2px solid #9fafc1;
            outline-offset: 3px;
            outline-style: dashed;
        }

        &--suffix {
            width: 100%;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-radius: 0px;
            margin-right: 0px;
        }
    }

    &__suffix {
        height: 32px;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 20px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border: none;
    }

    &__default {
        display: block;
        margin-left: 5px;
        margin-bottom: 25px;
    }

    &__drawer-colors {
        list-style: none;
        list-style-type: none;
        width: 95%;
        margin: 0px;
        margin-left: 7px;
        padding: 0px;

        li {
            margin-bottom: 15px;
        }

        padding-bottom: 20px;
    }

    &__btnwrapper {
        display: grid!important;
        grid-auto-flow: row;
        grid-template-columns: 150px 150px;
        grid-template-rows: auto;
        grid-gap: 5px;
        margin-right: 13px;
    }

    &__inner-content {
        display:flex;
        flex-direction: column;
        padding: 12px 12px;
        color: rgba(0, 0, 0, 0.65);

    }

    &__inner-content--two {
        display:flex;
        flex-direction: column;
        padding: 12px 12px;
        color: rgba(0, 0, 0, 0.65);

        .ant-popover-message {
            .ant-carousel {
                width: 317px;
                height: 10px!important;
            }
        }
    }

    &__option {
        min-width: 150px!important;
        margin: 5px;
        // color: #ffffff!important;
        border-radius: 0px;
    }

    &__cell {
        text-align: center;
        color: #ffffff;
        padding-top: 9px!important;
    }

    &__input {
        // color: white!important;
        height: 32px;
        line-height: 1.499;
        font-size: 14px!important;
        text-align: center;
        margin: 0px!important;

        &:read-only {
            background-color: #bfbfbf!important;
            color: #ffffff!important;
        }

        &:focus {
            box-shadow: none;
            outline: none;
            border: none;
        }


        width: 100%px;
        margin-right: 0px!important;
        border: none;
        border-top-right-radius: 0px!important;
        border-bottom-right-radius: 0px!important;
        border-radius: 0px;
    }

    &__newbtn {
        margin-left: 7px;
        margin-bottom: 15px;
        min-width: 148px;
        height: 30px;
        border-radius: 0px;
    }

    &__form {
        .ant-form-item {
            margin-bottom: 0px!important;
        }
    }

    &__switch {
        margin-left: 5px;
        margin-bottom: 25px;
    }
}

.ant-switch-checked {
    background-color: #3095de!important;
}

.select-color {

    margin-left: 7px;
    
    &__colors {
        margin-top: 5px;

        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 25px 25px 25px 25px;
        grid-template-rows: auto;
        grid-gap: 2px;
        width: 152px;
    }

    &__swatchbtn {
        height: 23px;
        width: 23px!important;
        min-width: 23px!important;
        padding: 0px;

        .text {
            color: #ffffff;
        }

        &--first {
            border-radius: 11px!important;

            .text {
                color: #595959!important;
            }
        }

    }

}

.select-swatches {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}